export default {
  proxy_path: "https://5bwdx43kg2.execute-api.us-west-2.amazonaws.com/api",
  proxy_path_geojson_api: "https://sa0d4ooa9k.execute-api.us-west-2.amazonaws.com/api",
  google_api_key: "AIzaSyD84bsWJslpBGrsiinmyTx5aLPpbXFz0a8",
  main_company_term_id: "6",
  crm_base_url: "https://crm.saas.forwoodsafety.com",
  forwood_id_url: "https://id.saas.forwoodsafety.com?redirect_uri=https://mapreport.saas.forwoodsafety.com",
  custom_tile_url: "https://mapoverlays.geoeditor.saas.forwoodsafety.com",
  Auth: {
    userPoolId: "us-west-2_3wLpx8Fn3",
    userPoolWebClientId: "38egocmm2dpr6ekj066kcpc4he",
    cookieStorage: {
      domain: ".saas.forwoodsafety.com",
      secure: true
    }
  }
};
